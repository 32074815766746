import React from "react";

const ProductBanner = ()=>{
    return <div className={"panel grid-container"}>
        <div>
            <img
                src="./package1.png"
                className="img-centre" style={{height:"150px",  maxWidth: "100%"}}/>
        </div>
        <div style={{margin:"auto"}}>
            <h3>Santa Photo Print Package</h3>
            <p>
                Incudes:
            </p>
            <p style={{marginLeft: "20px"}}>
                    Two x Print 8" x 6"
                    <br/>
                    Two x Print 6" x 4"
                    <br/>
                    Two x Print 3.5" x 5"
                </p>
        </div>
        <div className="circle" style={{margin:"auto"}}>
            $45
        </div>
    </div>
}

export default ProductBanner