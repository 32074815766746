import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faDownload, faCartPlus, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";

const testImg = "/package1.png"
let BaseURL = process.env.REACT_APP_BASEURL

const ImageGrid = ({downloadClickHandler, addToCartHandler, files, code})=>{
    const error = false
    
    let RenderedPhotos = ""

    if (files) {
        if (files.images.length>0) {
            RenderedPhotos = files.images.map((file,index)=>{
                return <PhotoCard key={index} file={file} index={index} code={code} downloadClickHandler={downloadClickHandler} addToCartHandler={addToCartHandler} />
            })
        }
    }
    
    return (
        <div className={"galleryViewView"}>
            {files ? <div className="grid-container">
                {RenderedPhotos}
            </div>:
                error? <div className={"error-message"}>
                        <div>
                            <FontAwesomeIcon icon={faExclamationCircle} className={"error-icon"}/>
                        </div>
                        <div style={{padding:"20px"}}>
                            <p>Images could not be found.</p>
                            <p>
                            Please call 02 9265 6800 for help.
                            </p>
                        </div>
            </div>:
                    <div className={"loading-spinner"}><FontAwesomeIcon icon={faCircleNotch} spin/>
                    </div>
            }

        </div>
    )
}

const PhotoCard = ({file, code, downloadClickHandler, addToCartHandler})=>{
    const [image, setImage] = React.useState()
    React.useEffect(()=>{
        setImage({path:`${code}/${file.fileName}`, id: file.fileName, link: file.url})
        /* axios.get(`${BaseURL}/public/files/${code}/${file}`)
            .then(res=> {
                setImage({path:`${code}/${file}`, id: file, link: res.data.link})
            }) */
    },[file])

    return (image?<div className={"image-card"}>
        <img
        src={image.link}
        className="img-centre galleryPhoto"/>
        <div className={"image-subtitle"}>
            <div className={"download-button"} onClick={()=>downloadClickHandler(image.link)/*downloadFileHandler(image[imageSize], `${order.subject.password}-image-${index}`)*/}>
                <div style={{padding: "5px"}}><FontAwesomeIcon icon={faDownload} />
                </div>
                <div style={{padding: "5px"}}>DOWNLOAD IMAGE</div>
            </div>
            <div className={"addToCart-button"} onClick={()=>addToCartHandler(image)}>
                <div style={{padding: "5px"}}><FontAwesomeIcon icon={faCartPlus} />
                </div>
                <div style={{padding: "5px"}}>Add Package to Cart</div>
            </div>
        </div>
    </div>
    :
    <div className={"loading-spinner"}>
        <FontAwesomeIcon icon={faCircleNotch} spin/>
    </div>
    )
}

export default ImageGrid